<template>
  <div>
    <el-card>
      <el-page-header
        :content="title"
        style="margin-bottom: 30px;"
        @back="goBack"
      />
      <nav class="out">
        <nav class="input">
          <el-input
            v-model="form_2.keyword"
            size="medium"
            placeholder="手机号，姓名，昵称"
          />
        </nav>
        <label
          for=""
          style="font-size: 14px; float: left; transform: translateY(9px)"
          >参与时间：</label
        >
        <nav class="input">
          <el-date-picker
            v-model="form_2.activityDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 100%"
            size="medium"
          />
        </nav>

        <nav class="input" style="width: 282px;">
          <label
            for=""
            style="font-size: 14px; float: left; transform: translateY(9px)"
            >中奖状态：</label
          >
          <el-select v-model="form_2.status" style="width: 200px;">
            <el-option label="全部" value="-1" />
            <el-option label="未中奖" value="0" />
            <el-option label="中奖" value="1" />
          </el-select>
        </nav>
        <nav class="input" style="width: 282px;">
          <label
            for=""
            style="font-size: 14px; float: left; transform: translateY(9px)"
            >发货状态：</label
          >
          <el-select v-model="form_2.isSend" style="width: 200px;">
            <el-option label="全部" value="-1" />
            <el-option label="已使用（积分，体验券）" :value="1" />
            <el-option label="未使用（积分，体验券）" :value="2" />
            <el-option label="已发放（实体和非实体奖品）" :value="3" />
            <el-option label="未发放（实体和非实体奖品）" :value="4" />
          </el-select>
        </nav>

        <nav class="input" style="width: 123px;margin-top: 4px;">
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="subimt">{{
            '搜索'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <!-- <el-table-column type="selection"></el-table-column> -->
        <el-table-column prop="id" width="100" label="编号" />
        <el-table-column prop="rotaryName" label="活动名称"></el-table-column>
        <el-table-column prop="nickname" label="姓名名称">
          <template slot-scope="{ row }">
            {{ row.realName || row.nickname }}
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" />
        <el-table-column prop="company" label="公司" />
        <el-table-column prop="job" label="职务" />
        <el-table-column prop="createTime" label="抽奖时间" />
        <el-table-column prop="awardName" label="奖品" />
        <el-table-column prop="awardName" label="状态">
          <template slot-scope="{ row }">
            <el-tag
              :type="row.awardType || row.awardType == 0 ? 'warning' : 'info'"
              size="samll"
              >{{ fState(row) }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="awardName" label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-if="!row.isSend && (row.awardType == 0 || row.awardType == 10)"
              type="text"
              icon="el-icon-s-promotion"
              size="mini"
              style="color:tan;"
              @click="open(row)"
              >发放奖品</el-button
            >
            <el-button
              v-if="row.awardType == 0"
              type="text"
              icon="el-icon-camera"
              size="mini"
              class="green"
              @click="seeTheLink(row)"
              >查看发货地址</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="form_2.page"
        :total="total"
        :page-size="form_2.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <popup
      ref="pop"
      title="输入快递单号"
      :id="6"
      @submit="submit"
      @getlist="getlist"
    ></popup>
    <popup ref="pop_7" title="发货地址" :id="7" @getlist="getlist"></popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btn: true,
      list: [],
      form_2: {
        page: 1,
        pageSize: 10,
        keyword: '',
        status: '',
        id: '',
        isSend: '',
        activityDate: []
      },
      title: '',
      form: {
        id: '',
        linkName: '',
        linkUrl: ''
      },

      total: 0,
      dialogVisible: false,
      dialogVisiblexui: false
    }
  },
  created() {
    this.form_2.isSend = +this.$route.query.isSend || ''
    this.getlist()
  },
  methods: {
    rowClass() {
      return 'background:#F5F7FA;'
    },
    async seeTheLink(row) {
      const { data } = await this.$http.get('/admin/Rotary/getAddress', {
        params: {
          id: row.id
        }
      })
      this.$refs.pop_7.form = {
        ...data.data
      }
      this.$refs.pop_7.show = true
    },
    open(row) {
      if (row.awardType == 0) {
        this.$refs.pop.show = true
        this.$refs.pop.form['id'] = row.id
        return
      }
      this.$confirm('请确认奖品已经发放', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.offeringPrizes(row)
        })
        .catch(() => {})
    },
    async submit(form) {
      const { data: res } = await this.$http.post('/admin/Rotary/awardsend', {
        ...form
      })
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.getlist()
        this.$refs.pop.show = false
      }
    },
    async offeringPrizes(row) {
      const { data: res } = await this.$http.post('/admin/Rotary/awardsend', {
        ...row
      })
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.getlist()
      }
    },
    f_status(val) {
      if (val == 0) {
        return '未开始'
      } else if (val == 1) {
        return '进行中'
      } else if (val == 2) {
        return '结束'
      }
    },
    fState(val) {
      if (!(val.awardType || val.awardType == 0)) {
        return '未中奖'
      }

      if (val.awardType > 0 && val.awardType != 10) {
        if (val.used) {
          return '已使用'
        } else {
          return '未使用'
        }
      } else {
        if (val.isSend) {
          return '已发放'
        } else {
          return '未发放'
        }
      }
    },
    theEditor() {},
    reset() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.getlist()
    },
    getlist() {
      this.form_2.id = this.$route.query.id
      //   console.log(this.$route)
      this.$http
        .get('/admin/Rotary/showData', {
          params: {
            ...this.form_2,
            activityDate: this.form_2.activityDate
              ? this.form_2.activityDate.join('~')
              : ''
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(res.data.list)
            this.list = res.data.list
            this.total = res.data.totalCount
            // console.log(this.list)
            this.title = res.data.rotary.name
          }
        })
    },
    edit(e) {
      this.form.id = e
      this.dialogVisible = true
      this.$http
        .get('/admin/FriendLink/getById?id=' + e)
        .then(({ data: res }) => {
          this.form.linkName = res.data.linkName
          this.form.linkUrl = res.data.linkUrl
        })
    },
    add() {
      if (this.btn) {
        this.$http
          .post('/admin/FriendLink/edit', this.form)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.getlist()
              this.$message.success(res.message)
              this.dialogVisible = false
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    // this

    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'activity_order_num'
        } else {
          s = 'rl_create_time'
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },
    handleSizeChange(e) {
      this.pagesize = e
      this.getlist()
    },
    handleCurrentChange(e) {
      this.form_2.page = e
      this.getlist()
    },
    subimt() {
      this.form_2.page = 1
      this.getlist()
    },
    goBack() {
      history.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.upload {
  width: 200px;
  height: 200px;
}
.skyblue {
  color: skyblue;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
</style>
